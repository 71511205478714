import * as React from "react"
import { graphql, Link, navigate } from "gatsby"
import Footer from "../components/Footer"
import { Container, Section } from "../components/global"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"

import Layout from "../components/Layout"
import Navbar from "../components/Navbar"
import Header from "../sections/Header"
import styled from "styled-components"
import ExternalLink from "../components/ExternalLink"
import SEOHead from "../components/SEOHead"
import { default as ContentGrid } from "../components/Grid"
import Card from "../components/Card"
import BlogCard from "../components/BlogCard"

import { useInView } from "react-intersection-observer"
import moment from "dayjs"

export default function Index({
  data: {
    allGraphCmsPhoto,
    allGraphCmsBlogPost: { posts },
    hero: { nodes: hero },
    me: { nodes: me },
  },
}) {
  const { ref, inView } = useInView({
    threshold: 0.2,
  })
  const heroImage = hero[0].value.localFile
  const myImage = me[0].value.localFile
  const getDate = node => node.blogPost?.date
  const writings = posts
    .sort((first, second) => moment(second.date).diff(moment(first.date)))
    .slice(0, 5)
  const photos = allGraphCmsPhoto.photos
    .sort((first, second) =>
      moment(getDate(second)).diff(moment(getDate(first)))
    )
    .slice(0, 9)

  return (
    <Layout>
      <SEOHead
        title="Home"
        description="Vinayaka K V's home page"
        image={getSrc(heroImage)}
      />
      <Navbar transperent={inView} />
      <Header reference={ref} image={getImage(heroImage)} />
      <Section>
        <Container>
          <Grid>
            <div>
              <h2>👋🏼 I am Vinayaka K V</h2>
              <p>
                I'm from the Western Ghat mountains of Karnataka, India. I work
                as a{" "}
                <ExternalLink href="https://dev.vinayakakv.com">
                  Software Engineer
                </ExternalLink>
                , and love to explore mountains and forests.
              </p>
              <p>
                I'm passionate about exploring the weather & climate, the
                biodiversity, and, human impacts on the ecosystem. Whenever I
                need to travel, I choose a route with lots of mountains,
                clicking out photos of things whatever I find interesting.
              </p>
              <p>
                Apart from the mountains and nature, I'm also a Coffee lover.
                Feel free to <Link to="#social">contact me</Link> to discuss
                anything from Mountains to Coffee!
              </p>
            </div>
            <Art>
              <svg style={{ position: "absolute", width: 0, height: 0 }}>
                <clipPath id="clip-path" clipPathUnits="objectBoundingBox">
                  <path d="M0.803,0.134 C0.858,0.211,0.86,0.309,0.895,0.399 C0.929,0.488,0.996,0.569,1,0.656 C1,0.742,0.946,0.834,0.858,0.902 C0.77,0.97,0.653,1,0.553,0.995 C0.452,0.976,0.368,0.891,0.259,0.829 C0.15,0.767,0.017,0.727,0.002,0.659 C-0.014,0.592,0.09,0.497,0.145,0.401 C0.201,0.306,0.209,0.21,0.267,0.133 C0.324,0.057,0.43,0,0.536,0 C0.643,0,0.749,0.057,0.803,0.134">
                    <animate
                      attributeType="XML"
                      attributeName="d"
                      values="M0.803,0.134 C0.858,0.211,0.86,0.309,0.895,0.399 C0.929,0.488,0.996,0.569,1,0.656 C1,0.742,0.946,0.834,0.858,0.902 C0.77,0.97,0.653,1,0.553,0.995 C0.452,0.976,0.368,0.891,0.259,0.829 C0.15,0.767,0.017,0.727,0.002,0.659 C-0.014,0.592,0.09,0.497,0.145,0.401 C0.201,0.306,0.209,0.21,0.267,0.133 C0.324,0.057,0.43,0,0.536,0 C0.643,0,0.749,0.057,0.803,0.134;
                      M0.254,0.176 C0.338,0.138,0.32,0.02,0.412,0.002 C0.504,-0.015,0.584,0.174,0.668,0.185 C0.752,0.196,0.875,0.136,0.926,0.23 C0.978,0.324,1,0.52,0.987,0.612 C0.952,0.703,0.791,0.714,0.713,0.806 C0.635,0.899,0.616,0.995,0.549,0.998 C0.481,1,0.327,1,0.244,0.945 C0.161,0.876,0.203,0.733,0.139,0.665 C0.075,0.599,-0.015,0.482,0.002,0.381 C0.02,0.281,0.171,0.214,0.254,0.176;
                      M0.803,0.134 C0.858,0.211,0.86,0.309,0.895,0.399 C0.929,0.488,0.996,0.569,1,0.656 C1,0.742,0.946,0.834,0.858,0.902 C0.77,0.97,0.653,1,0.553,0.995 C0.452,0.976,0.368,0.891,0.259,0.829 C0.15,0.767,0.017,0.727,0.002,0.659 C-0.014,0.592,0.09,0.497,0.145,0.401 C0.201,0.306,0.209,0.21,0.267,0.133 C0.324,0.057,0.43,0,0.536,0 C0.643,0,0.749,0.057,0.803,0.134;"
                      dur="10s"
                      repeatCount="indefinite"
                    />
                  </path>
                </clipPath>
              </svg>
              <GatsbyImage
                image={getImage(myImage)}
                alt=""
                imgStyle={{
                  clipPath: "url(#clip-path)",
                }}
              />
            </Art>
          </Grid>
        </Container>
      </Section>
      <Section accent="secondary">
        <Container>
          <Grid>
            <Visual>
              <ContentGrid
                style={{
                  "--auto-grid-min-size": "33%",
                  "--gap": "0.2rem",
                }}
              >
                {photos.map(({ id, src, caption }) => (
                  <Card
                    style={{
                      "--card-height": "10rem",
                      "--gap": "0",
                    }}
                    key={id}
                    onClick={() => navigate(`/photography?id=${id}`)}
                  >
                    <GatsbyImage
                      image={getImage(src.localFile)}
                      alt={caption}
                    />
                  </Card>
                ))}
              </ContentGrid>
              <br />
              <Link to="/photography">
                Look into more of my captures! &nbsp;&#x2794;
              </Link>
            </Visual>
            <TextContent>
              <h2>I do Photography</h2>
              <p>
                I enjoy photographing wild landscapes, panoramas and natural
                wonders. I also photograph while travelling, usually from a
                moving vehicle.
              </p>
            </TextContent>
          </Grid>
        </Container>
      </Section>
      <Section>
        <Container>
          <Grid reverse>
            <TextContent>
              <h2>I blog</h2>
              <p>
                I write about Mountains, Nature and Travel. My writings are
                usually paired with a handful of photographs. Think it as a
                story behind the photos!
              </p>
            </TextContent>
            <Visual>
              <ContentGrid style={{ "--auto-grid-min-size": "1fr" }}>
                {writings.map(node => (
                  <BlogCard
                    compact
                    {...node}
                    slug={`/writings/${node.slug}`}
                    key={node.slug}
                    style={{ "--card-body-gap": "1rem" }}
                  />
                ))}
              </ContentGrid>
              <br />
              <Link to="/writings">
                Explore more of my writings &nbsp;&#x2794;
              </Link>
            </Visual>
          </Grid>
        </Container>
      </Section>
      <Footer />
    </Layout>
  )
}

const TextContent = styled.main``

const Visual = styled.div``

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 40px;
  align-items: center;
  justify-items: center;

  h2 {
    margin-bottom: 16px;
  }

  ${props => (props.reverse ? "grid-template-columns: 2fr 3fr;" : "")}

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;

    ${Visual} {
      order: 2;
    }
  }
`

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`

export const query = graphql`
  {
    hero: allGraphCmsSitedata(filter: { name: { eq: "hero" } }) {
      nodes {
        name
        value {
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                sizes: "(max-width: 320px) 750px, (max-width: 750px) 1080px, 1920px"
              )
            }
          }
        }
      }
    }
    me: allGraphCmsSitedata(filter: { name: { eq: "me" } }) {
      nodes {
        name
        value {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    allGraphCmsPhoto(filter: { showInGallery: { eq: true } }) {
      photos: nodes {
        id: remoteId
        caption
        src {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, quality: 60)
            }
          }
        }
        blogPost {
          date
        }
      }
    }
    allGraphCmsBlogPost(sort: { fields: [date], order: DESC }, limit: 3) {
      posts: nodes {
        slug
        title
        date
        excerpt
        hero {
          src {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 200)
              }
            }
          }
        }
      }
    }
  }
`
