import React from "react"
import styled from "styled-components"
import { BgImage } from "gbimage-bridge"

const Header = ({ reference, image }) => {
  const colorUnit = "calc(var(--color-theme)*255)"
  const colorStart = `rgba(${colorUnit}, ${colorUnit}, ${colorUnit}, 0.4)`
  const colorEnd = `rgba(${colorUnit}, ${colorUnit}, ${colorUnit}, 0.4)`
  return (
    <HeaderWrapper>
      <BgImage
        image={image}
        style={{
          minWidth: "100%",
          minHeight: "100%",
          backgroundPosition: "70% 70%",
        }}
      >
        <HeroContent background={`linear-gradient(${colorStart}, ${colorEnd})`}>
          <h1 ref={reference} className="display">
            Mountains, Forests &amp; Travel
          </h1>
        </HeroContent>
      </BgImage>
    </HeaderWrapper>
  )
}

const HeaderWrapper = styled.header`
  height: 102vh;
  margin-top: calc(0px - 18px - 1.953rem);
`

const HeroContent = styled.main`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 64px;
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: minmax(0, 1fr);
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  color: var(--color-text);
  background: ${props => props.background};
`
export default Header
